import React from 'react'
import { useMatch, useResolvedPath } from 'react-router-dom'

// Components
import Link from '../../../common/components/Link'

// MUI
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';


const NavLinkItem = ({ title, icon, to, open, ...rest }) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: false });

    return (
        <Link to={to}>
            <ListItemButton
                selected={!!match}
                sx={{
                    // pl: 3, 
                    // pr: 3,
                    minHeight: 48,
                    px: 2.5,
                    justifyContent: open ? 'initial' : 'center',
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                    }}
                >
                    {icon}
                </ListItemIcon>
                <ListItemText primary={title} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
        </Link>
    )
}

export default NavLinkItem

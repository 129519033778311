import React from 'react'
import { styled } from '@mui/material/styles';

// MUI
import MuiDrawer from '@mui/material/Drawer';

// Utils
import { DrawerWidth, Colors } from '../../utils/constants'


const openedMixin = (theme) => ({
    width: DrawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: DrawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',

        '& .MuiPaper-root': {
            backgroundColor: `${Colors.black} !important`
        },

        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const DesktopDrawer = ({ open, drawer }) => {
    return (
        <Drawer
            variant="permanent"
            open={open}
            sx={{
                display: { xs: 'none', md: 'block' }
            }}
        >
            {drawer}
        </Drawer>
    )
}

export default DesktopDrawer

import React from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { SnackbarProvider } from "notistack";

// Utils
import ScrollToTop from "./common/router/ScrollToTop";

//Context
import { AuthProvider } from "./common/context/AuthContext";
import { StateProvider } from "./common/context/StateContext";
import { ThemeProvider } from "@mui/material/styles";

// Theme
import theme from "./common/utils/theme";

// Routes
import Routes from "./Routes";

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <HelmetProvider>
                    <SnackbarProvider maxSnack={3}>
                        <StateProvider>
                            <AuthProvider>
                                <ScrollToTop />
                                <Routes />
                            </AuthProvider>
                        </StateProvider>
                    </SnackbarProvider>
                </HelmetProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default App;

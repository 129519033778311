import React from 'react'
import { styled } from '@mui/material/styles'

// Context
import { useAuth } from '../../context/AuthContext'

// Components
import Button from '../../../common/components/Button'

// Utils
import { Colors, DrawerWidth } from '../../utils/constants'

// MUI
import Stack from '@mui/material/Stack';
import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';

// Images
import MenuIcon from '@mui/icons-material/Menu'
import Profile from '@mui/icons-material/AccountCircleOutlined'


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    backgroundColor: Colors.greyLight,
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: DrawerWidth,
        width: `calc(100% - ${DrawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const MainAppBar = ({ open, toggleDrawer }) => {
    const { logout, user: { email } } = useAuth()

    return (
        <AppBar
            position="fixed"
            open={open}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    edge="start"
                    sx={{
                        mr: { xs: 0, md: 5 },
                        ...(open && { display: 'none' }),
                    }}
                >
                    <MenuIcon color="primary" />
                </IconButton>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{ width: '100%' }}
                >
                    <Button
                        onClick={logout}
                        endIcon={<Profile sx={{ color: `${Colors.greyDark}`, fontSize: '28px !important' }} />}
                        variant="plain"
                        sx={{
                            whiteSpace: 'nowrap',
                            backgroundColor: 'initial',
                            padding: 0,
                            color: Colors.black,
                            textDecoration: "underline"
                        }}
                    >
                        Sign Out ({email})
                    </Button>
                </Stack>
            </Toolbar>
        </AppBar>
    )
}

export default MainAppBar

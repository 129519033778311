import React, { useState, useEffect, createContext, useContext, useCallback } from 'react'

// Context
import { StateContext } from './StateContext';

export const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
    const { setNotificationMessage } = useContext(StateContext)

    const [filterPanelOpen, setFilterPanelOpen] = useState(true);

    const toggleFilterPanel = () => {
        setFilterPanelOpen(!filterPanelOpen)
    }

    let value = {
        filterPanelOpen,
        toggleFilterPanel
    };

    return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>
}
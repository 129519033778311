import { createTheme } from "@mui/material/styles";
import { Colors } from "./constants";

const ThemeFontFamily = "'Gothic A1', sans-serif";

const theme = createTheme({
    typography: {
        fontFamily: ThemeFontFamily,
    },
    palette: {
        primary: {
            main: Colors.primary,
            light: Colors.accent,
            dark: Colors.accent
        },
        secondary: {
            main: Colors.accent
        },
        neutral: {
            main: Colors.black,
            light: Colors.white
        },
        info: {
            main: Colors.info
        },
        success: {
            main: Colors.green
        },
        error: {
            main: Colors.red
        },
        warning: {
            main: Colors.yellow
        },
    },
    shape: {
        borderRadius: 5
    },
    shadows: {
        0: "",
        1: `${Colors.shadow}`,
        4: `${Colors.shadow}`,
        6: `${Colors.shadowBig}`,
        8: `${Colors.shadowBig}`,
        16: `${Colors.shadowBig}`,
        24: `${Colors.shadowBig}`,
    }
});

const fontSize = 14;
const htmlFontSize = 16;
const coef = fontSize / 14;

theme.typography = {
    pxToRem: (size) => `${(size / htmlFontSize) * coef}rem`,
    h1: {
        fontWeight: 700,
        fontSize: "20px",
        color: Colors.black,
        fontFamily: ThemeFontFamily,
        [theme.breakpoints.up("md")]: {
            lineHeight: '26px',
            fontSize: '22px'
        },
    },
    h2: {
        fontWeight: 700,
        fontSize: "18px",
        fontFamily: ThemeFontFamily,
    },
    h3: {
        fontWeight: 700,
        fontSize: "16px",
        fontFamily: ThemeFontFamily,
    },
    h4: {
        fontWeight: 700,
        fontSize: "12px",
        fontFamily: ThemeFontFamily,
    },
    // h5: {
    //     fontWeight: 700,
    //     fontSize: "20px",
    //     fontFamily: ThemeFontFamily,
    // },
    // h6: {
    //     fontWeight: 700,
    //     fontSize: "16px",
    //     fontFamily: ThemeFontFamily,
    // },
    subheader: {
        fontWeight: 400,
        fontSize: "18px",
        color: Colors.greyDark,
        fontFamily: ThemeFontFamily,
    },
    body: {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        color: Colors.black,
        fontFamily: ThemeFontFamily,
    },
    bodyBold: {
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "22px",
        color: Colors.black,
        fontFamily: ThemeFontFamily,
    },
    // subtitle: {
    //     fontWeight: 400,
    //     fontSize: "12px",
    //     color: Colors.greyDark,
    //     fontFamily: ThemeFontFamily,
    // },
    link: {
        fontWeight: 700,
        fontSize: "14px",
        color: Colors.primary,
        fontFamily: ThemeFontFamily,
    },
    meta: {
        fontFamily: ThemeFontFamily,
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "24px",
        color: Colors.greyDark,

        background: Colors.greyMed,
        lineHeight: 'initial',
        padding: '5px 14px',
        borderRadius: '10px',
        minWidth: 'fit-content',
        height: 'fit-content'
    },
    tag: {
        fontWeight: 400,
        fontSize: "12px",
        color: Colors.white,
        fontFamily: ThemeFontFamily,
    },
};

theme.components = {
    MuiPaper: {
        styleOverrides: {
            root: {
                //borderRadius: 7,
                // '&.MuiDialog-paper': {
                //     margin: '8px !important',
                //     width: 'auto !important'
                // }
            },
        },
    },
    MuiTypography: {
        defaultProps: {
            variantMapping: {
                paragraph: "p", // This was done because the 'paragraph' variant was mapping to <span>
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: ({ ownerState }) => ({
                fontFamily: ThemeFontFamily,
                fontWeight: "700",
                height: "fit-contents",
                ...(ownerState.variant === "contained" && {
                    backgroundColor: Colors.accent,
                    color: theme.palette.primary.contrastText,
                    border: `2px solid ${Colors.accent}`,
                    "&:hover": {
                        backgroundColor: Colors.primary,
                        border: `2px solid ${Colors.primary}`,
                    },
                }),
                ...(ownerState.variant === "outlined" && {
                    border: `2px solid ${Colors.accent}`,
                    "&:hover": {
                        backgroundColor: Colors.accent,
                        color: theme.palette.primary.contrastText,
                        border: `2px solid ${Colors.accent}`,
                    },
                }),
                ...(ownerState.disabled && {
                    backgroundColor: `${Colors.greyMed} !important`,
                    color: Colors.greyDark,
                    border: `2px solid ${Colors.greyMed} !important`,
                    ...(ownerState.variant === "plain" && {
                        backgroundColor: `transparent !important`,
                        color: `${Colors.greyMed} !important`,
                        border: `0px solid transparent !important`,
                    }),
                })
            }),
        },
        variants: [
            {
                props: { variant: "plain" },
                style: {
                    backgroundColor: "transparent",
                    color: Colors.accent,
                    "&:hover": {
                        backgroundColor: "transparent",
                        opacity: 0.8
                    },
                },
            },
            {
                props: { variant: "danger" },
                style: {
                    backgroundColor: Colors.red,
                    borderColor: Colors.red,
                    color: Colors.white,
                    "&:hover": {
                        backgroundColor: Colors.red,
                        opacity: 0.9
                    },
                },
            },
            {
                props: { variant: "success" },
                style: {
                    backgroundColor: Colors.green,
                    borderColor: Colors.green,
                    color: Colors.white,
                    "&:hover": {
                        backgroundColor: Colors.green,
                        opacity: 0.9
                    },
                },
            },
            {
                props: { variant: "plain-white" },
                style: {
                    fontWeight: 900,
                    fontSize: "12px",
                    color: Colors.white,
                    ".MuiSvgIcon-root": {
                        fontSize: "24px",
                    },
                },
            },
        ],
    },
    // MuiIconButton: {
    //     styleOverrides: {
    //         root: () => ({
    //             "&:hover": {
    //                 backgroundColor: Colors.primary
    //             }
    //         })
    //     }
    // },
    MuiListItemButton: {
        styleOverrides: {
            root: {
                ".MuiSvgIcon-root": {
                    fontSize: 20,
                    color: Colors.greyDark,
                },
                ".MuiListItemText-root": {
                    color: Colors.white
                },
                "&:after": {
                    content: `''`,
                    opacity: "0",
                    position: "absolute",
                    right: "0",
                    top: "50%",
                    transform: "translateY(-50%)",
                    width: "6px",
                    backgroundColor: Colors.accent,
                    height: "42px",
                    transition: '0.3s ease'
                },
                "&.Mui-selected": {
                    "&:after": {
                        opacity: "1",
                    },
                    ".MuiSvgIcon-root": {
                        color: Colors.accent,
                    },
                    ".MuiListItemIcon": {
                        color: Colors.yellow,
                    },
                },
            },
        },
    },
    // MuiLink: {
    //     styleOverrides: {
    //         root: {
    //             fontFamily: ThemeFontFamily,
    //         },
    //     },
    // },
    MuiStepButton: {
        styleOverrides: {
            root: {
                fontFamily: ThemeFontFamily,
                fontSize: "14px",
                fontWeight: 700
            },
        },
    },
    MuiSlider: {
        styleOverrides: {
            root: {
                "& .MuiSlider-rail": {
                    backgroundColor: Colors.greyLight
                },
                "& .MuiSlider-track": {
                    backgroundColor: theme.palette.neutral.light,
                    borderColor: Colors.primary
                },
                "& .MuiSlider-thumb": {
                    color: theme.palette.neutral.light,
                    width: "14px",
                    height: "14px",
                },
            },
        },
    },
    MuiFormControlLabel: {
        styleOverrides: {
            root: {
                marginRight: 0,
                fontFamily: ThemeFontFamily,
                fontSize: "14px",
            },
        },
    },
    MuiFormHelperText: {
        styleOverrides: {
            root: {
                fontFamily: ThemeFontFamily,
                fontSize: "12px",
            },
        },
    },
    MuiTextField: {
        styleOverrides: {
            root: {
                fontFamily: ThemeFontFamily,
                fontSize: "14px"
            },
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                fontFamily: ThemeFontFamily,
                fontSize: '14px'
            },
        },
    },
    MuiTabs: {
        styleOverrides: {
            root: {
                ".MuiTabs-indicator": {
                    backgroundColor: Colors.primary,
                },
            },
        },
    },
    MuiTab: {
        styleOverrides: {
            root: {
                fontFamily: ThemeFontFamily,
                fontWeight: 700,
                "&.Mui-selected": {
                    color: Colors.primary,
                },
            },
        },
    },
    MuiToggleButtonGroup: {
        styleOverrides: {
            root: {
                border: `2px solid ${Colors.accent}`,
                padding: "4px",
            },
        },
    },
    MuiToggleButtonGroup: {
        styleOverrides: {
            root: {
                border: `2px solid ${Colors.accent}`
            },
        },
    },
    MuiToggleButton: {
        styleOverrides: {
            root: {
                fontFamily: ThemeFontFamily,
                padding: "10px 25px",
                margin: '4px',
                borderColor: "transparent",
                fontWeight: 700,
                color: Colors.accent,
                transition: "all 0.3s ease",

                "&.MuiToggleButtonGroup-grouped": {
                    borderRadius: "5px !important",
                },
                "&.Mui-selected": {
                    backgroundColor: Colors.accent,
                    color: Colors.white,
                    "&:hover": {
                        backgroundColor: Colors.primary,
                    },
                },
                "&:hover": {
                    backgroundColor: Colors.accent,
                    color: Colors.white,
                },
            },
        },
    },
    MuiDataGrid: {
        styleOverrides: {
            root: {
                "& .MuiDataGrid-columnHeader": {
                    outline: 'none !important'
                },
                "& .MuiDataGrid-iconSeparator": {
                    display: 'none'
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                    fontFamily: ThemeFontFamily,
                    fontSize: '14px',
                    fontWeight: 700,
                    color: Colors.greyDark
                },
                '& .MuiDataGrid-cell': {
                    outline: 'none !important',
                    fontFamily: ThemeFontFamily,
                    fontSize: '14px',
                    color: Colors.black
                }
            }
        }
    },
    MuiCalendarPicker: {
        styleOverrides: {
            root: {
                fontFamily: ThemeFontFamily
            }
        }
    },
    MuiListItem: {
        styleOverrides: {
            root: {
                fontFamily: ThemeFontFamily,
                fontSize: "14px"
            }
        }
    },
    MuiTable: {
        styleOverrides: {
            root: {
                fontFamily: ThemeFontFamily,
                fontSize: "14px"
            }
        }
    },
    MuiAlert: {
        styleOverrides: {
            root: {
                fontFamily: ThemeFontFamily,
                fontSize: "14px",
                textAlign: "left",
                '& .MuiAlert-icon': {
                    alignItems: 'center',
                }
            }
        }
    },
    MuiAlertTitle: {
        styleOverrides: {
            root: {
                fontFamily: ThemeFontFamily,
            }
        }
    },
    MuiAccordion: {
        styleOverrides: {
            root: {
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                    transform: 'rotate(90deg)',
                },
            },
        },
    },
};

export default theme;

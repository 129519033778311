import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";

// Provider
import { DashboardProvider } from "../context/DashboardContext";

// Containers
import MainAppBar from "./containers/MainAppBar";
import DesktopDrawer from "./containers/DesktopDrawer";
import MobileDrawer from "./containers/MobileDrawer";
import DrawerLinkList from "./containers/DrawerLinkList";
import { useNavigate } from "react-router-dom";

// Context
import { useAuth } from "../../common/context/AuthContext";

// Utils
import { Colors, DrawerWidth } from "../utils/constants";

// Mui
import Box from "@mui/material/Box";

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    minHeight: "64px !important",
}));

const DashboardLayout = () => {
    const navigate = useNavigate();
    const { isLoggedIn } = useAuth();

    const [open, setOpen] = useState(true);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/", { replace: true });
        }
    }, [isLoggedIn, navigate]);

    useEffect(() => {
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        if (width < 900) {
            setIsMobile(true);
            setOpen(false);
        }
    }, []);

    const toggleDrawer = () => setOpen(!open);

    const drawer = <DrawerLinkList open={open} toggleDrawer={toggleDrawer} />;

    return (
        <DashboardProvider>
            <Box sx={{ display: "flex" }}>
                <MainAppBar open={open} toggleDrawer={toggleDrawer} />

                {isMobile ? <MobileDrawer open={open} drawer={drawer} toggleDrawer={toggleDrawer} /> : <DesktopDrawer open={open} drawer={drawer} />}

                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        width: { md: `calc(100% - ${DrawerWidth}px)` },
                        minHeight: "100vh",
                        bgcolor: Colors.greyLight,
                        position: "relative",
                    }}
                >
                    {/* This is needed to space the content from the top */}
                    <DrawerHeader />
                    {/* **** */}

                    {/* Rendering component through React Router Dom */}
                    <Outlet />
                </Box>
            </Box>
        </DashboardProvider>
    );
};

export default DashboardLayout;

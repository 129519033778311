import React from 'react'
import { Outlet } from 'react-router-dom'

// MUI
import Stack from "@mui/material/Stack"

// Images
import Banner from '../../images/banner_auth.jpg'


const AuthLayout = () => {
	return (
		<Stack
			direction={{ xs: 'column', md: 'row' }}
			height='100vh'
			alignItems='center'
			justifyContent='center'
		>
			<Stack
				display={{ xs: 'none', md: 'flex' }}
				width='50%'
				height='100vh'
				sx={{
					backgroundImage: `url(${Banner})`,
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
				}}
			>
			</Stack>
			<Outlet />
		</Stack>
	)
}

export default AuthLayout
import React, { createContext, useEffect, useState } from 'react'
import { useSnackbar } from 'notistack';

// Components
import Slide from '@mui/material/Slide';

export const StateContext = createContext()

const TransitionDown = (props) => {
    return <Slide {...props} direction="down" />;
}

export const StateProvider = ({ children }) => {
    const [notificationMessage, setNotificationMessage] = useState()
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        if (notificationMessage) {
            enqueueSnackbar(notificationMessage.message, {
                variant: notificationMessage.type,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                TransitionComponent: TransitionDown,
            })
        }
    }, [notificationMessage, enqueueSnackbar])

    return (
        <StateContext.Provider value={{ setNotificationMessage }}>
            {children}
        </StateContext.Provider>
    );
}
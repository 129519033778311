import React, { useEffect, useState } from 'react'
import { useLocation, Navigate } from "react-router-dom";

// Component
import Loader from '../components/Loader';

// Context
import { useAuth } from '../context/AuthContext'

const RequireAuth = ({ children }) => {
    const [loading, setLoading] = useState(true)
    let auth = useAuth();
    let location = useLocation();

    useEffect(() => {
        if (!auth.loadingLogin) {
            setLoading(false)
        }
    }, [auth.loadingLogin])


    if (loading) {
        return <Loader fullscreen />
    }

    if (!auth.isLoggedIn) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return children
}

export default RequireAuth
import React from 'react'
import styled from '@emotion/styled'
import { Link as RouterLink } from 'react-router-dom';

// Mui
import MuiLink from '@mui/material/Link';

// Utils
import { Colors } from '../utils/constants';

const StyledLink = styled(MuiLink)`
    font-size: 14px;
    font-weight: 700;
    color: ${Colors.black};
    text-decoration: underline;
    transition: 0.3s ease;

    &:hover {
        color: ${props => props.light === "true" ? Colors.greyMed : Colors.primary}
    }

    ${props => props.light === "true" && `
        color: ${Colors.white};
    `}

    ${props => props.disabled === "true" && `
        color: ${Colors.greyMed};
        pointer-events: none;
    `}
`

const Link = React.forwardRef(({
    children,
    light,
    underline = 'none', // MUI values: 'none', 'always', 'hover'
    color = "primary",
    disabled,
    ...props
}, ref) => {
    return (
        <StyledLink
            ref={ref}
            component={RouterLink}
            light={light ? "true" : ""}
            underline={underline}
            color={color}
            disabled={disabled ? "true" : ""}
            {...props}
        >
            {children}
        </StyledLink>
    )
})

export default Link

import React from 'react'

// Utils
import { Colors } from '../../utils/constants'

// Components
import NavLinkItem from '../components/NavLinkItem'
import Link from '../../components/Link'

// MUI
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'


// Images
import logo from "../../../images/logo_light.png"

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import InsightsIcon from '@mui/icons-material/Insights';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HandshakeIcon from '@mui/icons-material/Handshake';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import DownloadIcon from '@mui/icons-material/Download';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import SecurityIcon from '@mui/icons-material/Security';


const DrawerLinkList = ({ open, toggleDrawer }) => {
    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                ml={3}
                mr={1}
            >
                <Box
                    component="img"
                    src={logo}
                    alt="Alignd Logo"
                    my={2}
                    sx={{
                        width: "120px"
                    }}
                />
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeftIcon color='secondary' />
                </IconButton>
            </Stack>

            <List>
                <NavLinkItem
                    title="Enrolment"
                    icon={<InsightsIcon />}
                    to="enrolment"
                    open={open}
                />
                <NavLinkItem
                    title="Patient Care"
                    icon={<PeopleIcon />}
                    to="patient-care"
                    open={open}
                />

                <NavLinkItem
                    title="Patient Outcomes"
                    icon={<AssignmentIcon />}
                    to="patient-outcomes"
                    open={open}
                />
                <NavLinkItem
                    title="Care Partners"
                    icon={<HandshakeIcon />}
                    to="care-partners"
                    open={open}
                />
                <NavLinkItem
                    title="Providers"
                    icon={<LocalHospitalIcon />}
                    to="providers"
                    open={open}
                />
            </List>

            <Divider
                sx={{
                    width: "190px",
                    margin: "8px auto",
                    borderColor: Colors.greyDark
                }}
            />

            <List>
                <NavLinkItem
                    title="Data Export"
                    icon={<DownloadIcon />}
                    to="data-export"
                    open={open}
                />
                <NavLinkItem
                    title="Data Validation"
                    icon={<AssignmentTurnedInIcon />}
                    to="data-validation"
                    open={open}
                />
            </List>

            <Divider
                sx={{
                    width: "190px",
                    margin: "8px auto",
                    borderColor: Colors.greyDark
                }}
            />

            <List>
                <NavLinkItem
                    title="Data &amp; Privacy"
                    icon={<SecurityIcon />}
                    to="TODO"
                    open={open}
                />
            </List>
        </>
    )
}

export default DrawerLinkList
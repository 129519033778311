import React, { useState, useEffect, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

// Services
import AuthService from "../../services/auth.service";

// Firebase
const firebaseConfig = {
    apiKey: "AIzaSyCwzWA3Zt0diL1D7jVd2_mYCGtyP7esQeM",
    authDomain: "smart-processor-303316.firebaseapp.com",
    projectId: "smart-processor-303316",
    storageBucket: "smart-processor-303316.appspot.com",
    messagingSenderId: "155396496738",
    appId: "1:155396496738:web:b69eebd0b7b34b2c4e203b",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const provider = new GoogleAuthProvider();

    const [loadingLogin, setLoadingLogin] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState();
    const [user, setUser] = useState({
        first_name: "",
        last_name: "",
        email: "",
    });

    useEffect(() => {
        const user = AuthService.getCurrentUser();
        if (user) {
            setUser(user);
            setIsLoggedIn(true);
        } else {
            setLoadingLogin(false);
        }
    }, []);

    const login = async (values, callback) => {
        const loginCall = await AuthService.login(values);
        if (loginCall.status) {
            setUser({
                first_name: loginCall.data.first_name,
                last_name: loginCall.data.last_name,
                email: loginCall.data.email,
            });
            setIsLoggedIn(true);
            callback(true);
        } else {
            callback(false, loginCall);
        }
    };

    const signInWithGoogle = async (callback) => {
        signInWithPopup(auth, provider)
            .then((result) => {
                const token = result.user.accessToken;

                AuthService.socialLogin(token).then((response) => {
                    if (response.status) {
                        setUser({
                            first_name: response.data.first_name,
                            last_name: response.data.last_name,
                            email: response.data.email,
                        });
                        setIsLoggedIn(true);
                        callback(true, response);
                    } else {
                        callback(false, response);
                    }
                });
            })
            .catch((error) => {
                const errorMessage = error.message;
                callback(false, errorMessage);
            });
    };

    const logout = () => {
        AuthService.logout();
        setIsLoggedIn(false);
        navigate("/", { replace: true });
    };

    let value = { isLoggedIn, loadingLogin, login, signInWithGoogle, logout, user };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export const Colors = {
    primary: "#1F667A",
    accent: "#BE9B2D",

    accentOrange: "#E07D24",
    accentYellow: "#E7BA20",
    accentRed: "#9F3D41",
    accentBlue: "#009DBC",
    accentGreen: "#8E9D35",

    white: "#FFFFFF",
    black: "#231F20",
    greyLight: "#F3F4F6",
    greyMed: "#C1BFB7",
    greyDark: "#979797",

    green: "#3DA260",
    red: "#E85A6C",
    yellow: "#FFB100",
    info: "#51a6ca",

    shadow: "0 6px 15px rgba(0 0 0 / 0.1)",
    shadowBig: "0 6px 30px rgba(0 0 0 / 0.2)",
};

export const DrawerWidth = 230;
export const PageGutter = "2%";
export const BlockSpacing = 3;

export const ValidationMessages = {
    required: "Required",
    invalidEmail: "Invalid Email",
    passwordTooShort: "Password is too short (minimum 6 characters)",
    passwordNotMatch: "Passwords not match",
};

export const NotificationMessages = {
    auth: {
        inactiveUser: "This account is not activated. An activation email has been re-sent to your email.",
        authFailed: "Invalid email or password provided.",
        invalidKey: "Invalid key provided.",
        usedKey: "This activation key has already been used.",
        activationSuccess: "Your account has been successfully activated.",
        userExists: "A user with this email already exists.",
        userDoesNotExist: "A user with this email does not exist.",
        activationEmailSent: "An activation link has been sent to your email.",
        resetPasswordEmailSent: "A reset password link has been sent to your email.",
        passwordResetSuccess: "Your password has been reset. You will be redirected to the sign in page shortly.",
    },
    genericErrorMessage: "Something went wrong.",
    getDataError: "Error fetching {0} data.",
};

import React from 'react'

// Utils
import { Colors, DrawerWidth } from '../../utils/constants'

// MUI
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';


const MobileDrawer = ({ open, drawer, toggleDrawer }) => {
    return (
        <Box
            component="nav"
            sx={{
                width: { md: DrawerWidth },
                flexShrink: { md: 0 },
                display: {
                    xs: 'block',
                    md: 'none'
                }
            }}
        >
            <Drawer
                sx={{
                    width: DrawerWidth,
                    flexShrink: 0,
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': {
                        width: DrawerWidth,
                        boxSizing: 'border-box',
                        backgroundColor: `${Colors.black} !important`
                    },
                }}
                ModalProps={{
                    keepMounted: true,
                }}
                onClose={toggleDrawer}
                open={open}
                variant="temporary"
                anchor="left"
            >
                {drawer}
            </Drawer>
        </Box>
    )
}

export default MobileDrawer

import api from "./api";
import _cloneDeep from "lodash/cloneDeep";
import TokenService from "./token.service";
import { handleError } from "./utils";

const isBrowser = typeof window !== `undefined`;

const register = ({ first_name, last_name, email, occupation, password }) => {
    if (!isBrowser) return false;

    let lowercaseEmail;
    if (email) {
        lowercaseEmail = email.toLowerCase();
    }
    return api
        .post("/accounts/register/", {
            first_name,
            last_name,
            email: lowercaseEmail,
            occupation,
            password,
        })
        .then((response) => {
            if (response.status === 201) {
                return {
                    status: true,
                };
            }
        })
        .catch((err) => {
            return handleError(err);
        });
};

const login = ({ username, password }) => {
    if (!isBrowser) return false;

    let lowercaseUsername;
    if (username) {
        lowercaseUsername = username.toLowerCase();
    }

    return api
        .post("/accounts/token/", {
            username: lowercaseUsername,
            password,
        })
        .then((response) => {
            if (response.data.access) {
                TokenService.setUser(response.data);
                return {
                    status: true,
                    data: response.data,
                };
            }
        })
        .catch((err) => {
            return handleError(err);
        });
};

const socialLogin = (firebaseToken) => {
    if (!isBrowser) return false;

    return api
        .post("/accounts/social_login/", {
            firebase_token: firebaseToken,
        })
        .then((response) => {
            if (response.data.access) {
                TokenService.setUser(response.data);
                return {
                    status: true,
                    data: response.data,
                };
            }
        })
        .catch((err) => {
            return handleError(err);
        });
};

const activate = (activationKey) => {
    if (!isBrowser) return false;

    return api
        .put(`/accounts/activate/${activationKey}/`)
        .then(function (response) {
            if (response.status === 200) {
                return {
                    status: true,
                };
            }
        })
        .catch(function (error) {
            return handleError(error);
        });
};

const forgotPassword = ({ email }) => {
    if (!isBrowser) return false;

    let lowercaseEmail;
    if (email) {
        lowercaseEmail = email.toLowerCase();
    }
    return api
        .post("/accounts/forgot_password/", { email: lowercaseEmail })
        .then(function (response) {
            if (response.status === 200) {
                return {
                    status: true,
                };
            }
        })
        .catch(function (error) {
            return handleError(error);
        });
};

const resetPassword = (data, activationKey) => {
    if (!isBrowser) return false;

    return api
        .put(`/accounts/reset_password/${activationKey}/`, data)
        .then(function (response) {
            if (response.status === 200) {
                return {
                    status: true,
                };
            }
        })
        .catch(function (error) {
            return handleError(error);
        });
};

const logout = () => {
    TokenService.removeUser();
};

const getCurrentUser = () => {
    return TokenService.getUser();
};

const updateCurrentUser = ({ first_name, last_name }) => {
    const userClone = _cloneDeep(TokenService.getUser());
    userClone["first_name"] = first_name;
    userClone["last_name"] = last_name;
    TokenService.setUser(userClone);
};

const AuthService = {
    register,
    login,
    socialLogin,
    activate,
    forgotPassword,
    resetPassword,
    logout,
    getCurrentUser,
    updateCurrentUser,
};
export default AuthService;

import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";

// Utils
import RequireAuth from "./common/router/RequireAuth";

// Components
import Loader from "./common/components/Loader";

// Layout
import AuthLayout from "./common/layout/AuthLayout";
import DashboardLayout from "./common/layout/DashboardLayout";

// Common
import NotFound from "./common/router/NotFound";

// Auth
const AsyncLogin = lazy(() => import("./pages/auth/Login"));
const AsyncForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));
const AsyncResetPassword = lazy(() => import("./pages/auth/ResetPassword"));

// Dashboard
const AsyncEnrolment = lazy(() => import("./pages/dashboard/enrolment"));
const AsyncPatientCare = lazy(() => import("./pages/dashboard/patientCare"));
const AsyncPatientOutcomes = lazy(() => import("./pages/dashboard/patientOutcomes"));
const AsyncCarePartners = lazy(() => import("./pages/dashboard/carePartners"));
const AsyncProviders = lazy(() => import("./pages/dashboard/providers"));

const DashboardRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<AuthLayout />}>
                <Route
                    path=""
                    element={
                        <Suspense fallback={<Loader fullscreen={true} />}>
                            <AsyncLogin />
                        </Suspense>
                    }
                />

                <Route
                    path="forgot-password"
                    element={
                        <Suspense fallback={<Loader />}>
                            <AsyncForgotPassword />
                        </Suspense>
                    }
                />

                <Route
                    path="reset-password/:key"
                    element={
                        <Suspense fallback={<Loader />}>
                            <AsyncResetPassword />
                        </Suspense>
                    }
                />
            </Route>

            <Route path="/dashboard" element={<DashboardLayout />}>
                <Route
                    path="enrolment"
                    element={
                        <Suspense fallback={<Loader />}>
                            <RequireAuth>
                                <AsyncEnrolment />
                            </RequireAuth>
                        </Suspense>
                    }
                />
                <Route
                    path="patient-care"
                    element={
                        <Suspense fallback={<Loader />}>
                            <RequireAuth>
                                <AsyncPatientCare />
                            </RequireAuth>
                        </Suspense>
                    }
                />
                <Route
                    path="patient-outcomes"
                    element={
                        <Suspense fallback={<Loader />}>
                            <RequireAuth>
                                <AsyncPatientOutcomes />
                            </RequireAuth>
                        </Suspense>
                    }
                />
                <Route
                    path="care-partners"
                    element={
                        <Suspense fallback={<Loader />}>
                            <RequireAuth>
                                <AsyncCarePartners />
                            </RequireAuth>
                        </Suspense>
                    }
                />
                <Route
                    path="providers"
                    element={
                        <Suspense fallback={<Loader />}>
                            <RequireAuth>
                                <AsyncProviders />
                            </RequireAuth>
                        </Suspense>
                    }
                />
            </Route>
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default DashboardRoutes;

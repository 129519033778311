import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

// Global Styles
import { Global, css } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";

const GlobalStyle = css`
    html {
        scroll-behavior: smooth;
    }

    body {
        font-family: "Gothic A1", sans-serif;
        font-weight: 400;
        margin: 0;
    }

    .SnackbarContent-root {
        .SnackbarItem-message {
            font-family: "Gothic A1", sans-serif;
            font-size: 14px;
        }
        .MuiSvgIcon-root {
            color: white !important;
        }
    }
`;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <>
        <React.StrictMode>
            <Global styles={GlobalStyle} />
            <CssBaseline />
            <App />
        </React.StrictMode>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from 'react'

// Components
import Link from '../components/Link'
import Typography from '../components/Typography'

// MUI
import Stack from "@mui/material/Stack"


const NotFound = () => {
    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
                p: 4,
                minHeight: '100vh'
            }}
        >
            {/* <img src={Logo} /> */}
            <Typography variant='h4' pt={6}>Page Not Found!</Typography>
            <Typography variant='body' mt={4} pb={6} sx={{ textAlign: 'center' }}>The page you are looking for no longer exists or has been moved.</Typography>
            <Link to="/">Alignd Home</Link>
        </Stack>
    )
}

export default NotFound